import { getStudyAndSOPInstanceUIDs, isDicom } from "./dicom"
import { ExtendedDicomFile } from "./types"

const getExtendedDicomFile = async (file: File): Promise<ExtendedDicomFile> => {
    const extendedFile = file as ExtendedDicomFile
    const isDicomFile = await isDicom(file)

    if (isDicomFile) {
        const { StudyInstanceUID, SOPInstanceUID } = await getStudyAndSOPInstanceUIDs(file)

        extendedFile.isDicom = true
        extendedFile.StudyInstanceUID = StudyInstanceUID
        extendedFile.SOPInstanceUID = SOPInstanceUID
    } else {
        extendedFile.isDicom = false
        extendedFile.StudyInstanceUID = ''
        extendedFile.SOPInstanceUID = ''
    }

    return extendedFile
}

export { getExtendedDicomFile }