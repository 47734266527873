import React, { useEffect, useState } from "react";
import { StudyMetadata, useFileState } from "../../providers/FileStateProvider";
import { searchForPatientByMrn } from "../../lib/api/wms";
import { PatientInfo } from "../../../../sharedTypes/wmsQueryTypes";
import { getDICOMFormattedPatientInfo, DICOMFormatPatientInfo } from "../../lib/patientInformation/getDICOMFormattedPatientInfo";
import PatientInfoInput from "../PatientInfoInput/PatientInfoInput";
import "./UserInput.css";
import UserInputFooterIdentified from "./UserInputFooterIdentified";
import AttachmentsWithDropDown from "./AttachmentsWithDropDown";
import LoadingSpinner from "../Shared/LoadingSpinner";


const IdentifiedOrderLevelInput = () => {
    const {
        studyMetadata,
        uploadInput
    } = useFileState()

    /**
     * Identified Patient
     */
    const [defaultPatientInfo, setDefaultPatientInfo] = useState<DICOMFormatPatientInfo | undefined>(undefined)
    const [loading, setIsLoading] = useState<boolean>(true);

    const searchForIdentifiedPatient = async (mrn: string, siteId: string): Promise<PatientInfo | undefined> => {
        const sitePatientForMrn = await searchForPatientByMrn(uploadInput, mrn, siteId)

        if (sitePatientForMrn !== undefined) {
            return sitePatientForMrn.patientInfo;
        }
    }

    const setPatientInfoFromWMSResultOrDicom = (studyMetadata: StudyMetadata[], patientInfoFromWMS?: PatientInfo) => {
        const dicomFormattedPatientInfo = getDICOMFormattedPatientInfo(studyMetadata, patientInfoFromWMS)

        setDefaultPatientInfo(dicomFormattedPatientInfo);

        return dicomFormattedPatientInfo;
    }

    const patientMatching = async (): Promise<void> => {
        const order = uploadInput.order;

        if (order === undefined) {
            throw new Error('Should have order when at order level user input');
        }

        const siteId = order.siteId;
        const mrn = order.MedicalRecordNumber;

        const patientInfo = await searchForIdentifiedPatient(mrn, String(siteId));

        if (!patientInfo) {
            throw new Error('Should have complete patient info when at order level user input')
        }

        setPatientInfoFromWMSResultOrDicom(studyMetadata, patientInfo)
        setIsLoading(false);
    }

    /**
     * Search for the patient based on the MRN on page load.
     * Don't do this for blind trial uploads, we will always create a new patient.
     */
    useEffect(() => {
        patientMatching();
    }, [])

    if (loading) {
        return <div><LoadingSpinner /></div>
    }

    return (
        <div className="user-input-layout">
            <PatientInfoInput
                title={'Patient Information'}
                defaultPatientInfo={defaultPatientInfo}
            />
            <AttachmentsWithDropDown />
            <UserInputFooterIdentified
                canUpload={true}
                defaultPatientInfo={defaultPatientInfo}
                userDefinedPatientInfo={{}}
            />
        </div>
    );
}


export default IdentifiedOrderLevelInput