import React from "react";
import { useFileState } from "../providers/FileStateProvider";
import { filterFiles } from "../lib/uploadUtils";
import "./FileUploadInput.css"
import DropZone from "./Shared/DropZone";
import './DicomSelector.css'
import './Shared/DropZone.css';

const DicomSelector = ({ disabled }: { disabled?: boolean }) => {
    const { api, files: existingFiles } = useFileState()

    const selectFilesHandler = (event: any): void => {
        const filteredFiles = filterFiles(event.target.files)

        api.addFiles(existingFiles, filteredFiles)
    };

    const onDrop = (files: File[]): void => {
        const filteredFiles = filterFiles(files);

        api.addFiles(existingFiles, filteredFiles)
    }

    const primaryClassName = disabled ? "custom-file-upload-primary-disabled" : "custom-file-upload-primary"

    return (
        <div className="dicom-selector">
            <div className="dicom-select-select-folder">
                <label htmlFor="folder-selector" className={primaryClassName}>
                    Select Folder
                </label>
                <input
                    id="folder-selector"
                    disabled={disabled}
                    type="file"
                    onChange={selectFilesHandler}
                    directory=""
                    webkitdirectory=""
                    multiple
                />
            </div>
            <DropZone onDrop={onDrop} disabled={disabled} />
        </div>
    )
}

export default DicomSelector