import { DeidentificationProtocol } from "../../../../sharedTypes/deidentifyTypes"
import { DicomTagOverridesPerStudy } from "./types"
import { TagPatchConfigPerStudy, TagPatchConfig, AddPatch, VR } from "../../../../sharedTypes/deidentifyTypes"
import { getVrFromKeyword } from './vr'
import { StudyMetadata } from "../../providers/FileStateProvider"


const getValueForUserDefinedTag = (
    tag: string,
    studyHeader: StudyMetadata['header'],
    tagOverride?: string
): string => {
    if (tagOverride) {
        return tagOverride;
    }

    return studyHeader[tag] || ""
}

const getValueFromStudyHeaderTag = (studyHeader: StudyMetadata['header'], tag: string, vr: VR): string => {
    const studyHeaderTag = studyHeader[tag];

    if (!studyHeaderTag) {
        return "";
    }

    if (vr === "PN") {
        return studyHeaderTag?.[0]?.Alphabetic;
    }

    // If there is no default value given, use the value from the dicom (as the user has confirmed this is correct).
    // We need to do this to add it to the keep list, if we don't do this, it will be harshly deidentified by GCP.
    return studyHeader[tag];
}

const getValueForMandatoryTag = (
    fixed: boolean,
    vr: VR,
    tag: string,
    studyHeader: StudyMetadata['header'],
    defaultValue: string | undefined,
    tagOverride: string | undefined
): string => {
    // If the value is fixed, always return the default value.
    if (fixed) {
        // In the case of a provided fixed value we will have a default value, otherwise for "keep" it is just the studyheader tag.
        // Note the studyHeader tag may be empty
        const valueToUse = defaultValue || getValueFromStudyHeaderTag(studyHeader, tag, vr) || "";

        return valueToUse;
    }

    // If there is a user input override, use that.
    if (tagOverride) {
        return tagOverride
    }

    // If we have a default value, fall back to it.
    if (defaultValue !== undefined) {
        return defaultValue;
    }

    // If there is no default value given, use the value from the dicom (as the user has confirmed this is correct).
    // We need to do this to add it to the keep list, if we don't do this, it will be harshly deidentified by GCP.
    return getValueFromStudyHeaderTag(studyHeader, tag, vr);
}

const mapTagValueBasedOnVR = (vr: VR, value?: string): string => {
    if (!value) {
        return ''; // Type 2 empty string.
    }

    if (vr === 'PN') {
        return value.replaceAll(" ", "^")
    }

    // Dates are already in the correct format.
    return value
}

const generateTagPatchConfig = (
    mandatoryDeidentifyList: DeidentificationProtocol,
    userDefinedDeidentifyListPerStudy: string[] | undefined,
    dicomTagOverrides: Record<string, string> | undefined,
    studyHeader: StudyMetadata['header']
): TagPatchConfig => {
    const tagPatchConfig: TagPatchConfig = []

    mandatoryDeidentifyList.forEach(deidentificationProtocolTag => {
        const { tag, defaultValue, fixed } = deidentificationProtocolTag

        const vr = getVrFromKeyword(tag)
        const value = getValueForMandatoryTag(!!fixed, vr, tag, studyHeader, defaultValue, dicomTagOverrides?.[tag])
        const mappedValue = mapTagValueBasedOnVR(vr, value)

        const addPatch: AddPatch = {
            path: tag,
            op: 'add',
            value: {
                vr,
                Value: [mappedValue]
            }
        }

        tagPatchConfig.push(addPatch)
    })

    if (userDefinedDeidentifyListPerStudy !== undefined) {
        userDefinedDeidentifyListPerStudy.forEach(tag => {
            const vr = getVrFromKeyword(tag)
            const value = getValueForUserDefinedTag(tag, studyHeader, dicomTagOverrides?.[tag])
            const mappedValue = mapTagValueBasedOnVR(vr, value)

            const addPatch: AddPatch = {
                path: tag,
                op: 'add',
                value: {
                    vr: getVrFromKeyword(tag),
                    Value: [mappedValue]
                }
            }

            tagPatchConfig.push(addPatch)
        })
    }

    return tagPatchConfig
}


const generateTagPatchConfigPerStudy = (
    mandatoryDeidentifyList: DeidentificationProtocol,
    userDefinedDeidentifyListPerStudy: Record<string, string[]>,
    dicomOverridesPerStudy: DicomTagOverridesPerStudy,
    studyMetadata: StudyMetadata[]
) => {
    const tagPatchConfigPerStudy: TagPatchConfigPerStudy = {}


    studyMetadata.forEach(sm => {
        const { StudyInstanceUID, header: studyHeader } = sm;

        const dicomOverridesForStudy = dicomOverridesPerStudy[StudyInstanceUID]
        const userDefinedDeidentifyListForStudy = userDefinedDeidentifyListPerStudy[StudyInstanceUID]
        const tagPatchConfigForStudy = generateTagPatchConfig(
            mandatoryDeidentifyList,
            userDefinedDeidentifyListForStudy,
            dicomOverridesForStudy,
            studyHeader
        )


        tagPatchConfigPerStudy[StudyInstanceUID] = tagPatchConfigForStudy
    })

    return tagPatchConfigPerStudy
}

export { generateTagPatchConfigPerStudy }