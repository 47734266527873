import React, { useState } from 'react';
import './GuestUploaderManager.css';
import { auth0Axios } from '../../auth/auth0Axios';
import { SITE_URL_LOCAL_STORAGE_KEY } from '../../auth/localStorageKeys';



function GuestUploaderManager() {
  const wmsUrl: string | null = localStorage.getItem(SITE_URL_LOCAL_STORAGE_KEY)
  const [guestUploaderURL, setGuestUploaderURL] = useState<string | undefined>()

  // This page is for DEBUGGING and internal testing purposes only.
  if (wmsUrl === null || !['wms-d-site1.yunu.dev', 'wms-d-site2.yunu.dev'].includes(wmsUrl)) {
    return <></>
  }

  const createLongLivedOrganizationLevelGuestUploader = async () => {
    const config = {
      temporary: false,
      require_sender_info: true
    }

    void createGuestUploader(config)
  }

  const createLongLivedTrialGuestUploader = async () => {
    const config = {
      trial_id: "1",
      site_id: "1",
      uploader_message: 'Sample Guest Uploader Message',
      temporary: false
    }

    void createGuestUploader(config)
  }

  const createDisabledLongLivedTrialGuestUploader = async () => {
    const config = {
      trial_id: "1",
      site_id: "1",
      uploader_message: 'Sample Guest Uploader Message',
      temporary: false
    }

    const guestUploaderId = await createGuestUploader(config);

    void auth0Axios.patch('/api/guest-uploader', { id: guestUploaderId, disabled: true })
  }

  const failToCreateLongLivedTrialGuestUploaderWithoutSite = async () => {
    const config = {
      trial_id: "1",
      uploader_message: 'Sample Guest Uploader Message',
      temporary: false
    }

    void createGuestUploader(config)
  }

  const createTemporaryTrialGuestUploader = async () => {
    const config = {
      trial_id: "1",
      uploader_message: 'Example Temporary Trial Guest Uploader Message',
      temporary: true
    }

    void createGuestUploader(config)
  }

  const createTemporaryPatientGuestUploader = async () => {
    const config = {
      trial_id: "1",
      trial_patient_id: "1",
      uploader_message: 'Example Temporary Patient Guest Uploader Message',
      temporary: true,
    }

    void createGuestUploader(config)
  }

  const createTemporaryOrderGuestUploader = async () => {
    const config = {
      trial_id: "1",
      trial_patient_id: "1",
      order_id: "1",
      uploader_message: 'Example Temporary Order Guest Uploader Message',
      temporary: true,
    }

    void createGuestUploader(config)
  }

  const createTemporaryOrderGuestUploaderWithDeidConfig = async () => {
    const config = {
      trial_id: "1",
      trial_patient_id: "1",
      order_id: "1",
      default_tag_input: {
        protocol: [
          { // Add a new value not in that config and fix it.
            tag: "OperatorsName",
            defaultValue: "Mr^Operator",
            fixed: true
          },
          {
            // Add a disallowed tag
            tag: "PixelSpacing"
          }
        ]
      },
      uploader_message: 'Example Temporary Order With Included Deid Config Guest Uploader Message',
      temporary: true,
    }

    void createGuestUploader(config)
  }

  const createGuestUploader = async (config: any) => {
    const result = await auth0Axios.post('/api/guest-uploader', config)

    const guestUploaderId = result.data.id
    const guestUploaderURL = `${window.location.origin}/guest/${guestUploaderId}`

    setGuestUploaderURL(guestUploaderURL)

    return guestUploaderId;
  }

  return (
    <div className="App">
      <p>Guest Uploader Manager</p>
      <button onClick={createLongLivedOrganizationLevelGuestUploader}>Create Long lived Organization Level Guest Uploader</button>
      <button onClick={createLongLivedTrialGuestUploader}>Create Long lived Trial Level Guest Uploader (trial1/site1)</button>
      <button onClick={createDisabledLongLivedTrialGuestUploader}>Create Disabled Long lived Trial Level Guest Uploader (trial1/site1)</button>
      <button onClick={failToCreateLongLivedTrialGuestUploaderWithoutSite}>Fail To Create Long lived Trial Level Guest Uploader Without Site</button>
      <button onClick={createTemporaryTrialGuestUploader}>Create Temporary Trial Guest Uploader</button>
      <button onClick={createTemporaryPatientGuestUploader}>Create Temporary Patient Guest Uploader</button>
      <button onClick={createTemporaryOrderGuestUploader}>Create Temporary Order Guest Uploader</button>
      <button onClick={createTemporaryOrderGuestUploaderWithDeidConfig}>Create Temporary Order Guest Uploader With deidentify config</button>
      {guestUploaderURL ? <p>{guestUploaderURL}</p> : null}
    </div>
  );
}

export default GuestUploaderManager;

// TODO -> We will build the manager UI later on the WMS layer and use these build APIs:

// const getGuestUploaderResult = await auth0Axios.get(`/api/guest-uploader/${id}`)
// const guestUploader = getGuestUploaderResult.data


// await auth0Axios.patch(`/api/guest-uploader`, {
//   id: guestUploader.id,
//   uploader_message: 'Please upload lots of stuff!'
// })


// let listResult = await auth0Axios.get(`/api/guest-uploader-list`)

// console.log(listResult.data)


// // Comment out so we can create for tests
// const deleteResult = await auth0Axios.delete(`/api/guest-uploader/${guestUploader.id}`)
