// For uploads we can't know the exact times each step will take, but we can make a bloated guess and show a loading bar.

import { UploadInput, UploadLevel } from "../providers/FileStateProvider";

const FALLBACK = 60 * 1000; // 60s

const SEVENTY_PERCENT_MODIFIER = 1.0 / 0.7;

/**
 * Set estimated time, which we will use as 70% and use the rest as buffer.
 */
const TRANSFERING_DATA_TIME_PER_1000_IMAGES = 60 * 1000 * SEVENTY_PERCENT_MODIFIER; // 60 seconds per 1000 images.
const CONFIGURING_PATIENT = 20 * 1000 * SEVENTY_PERCENT_MODIFIER; // 20 seconds
const PROCESSING_DATA_PER_1000_IMAGES = 120 * 1000 * SEVENTY_PERCENT_MODIFIER; // 2 mins for 1000 images.
const ORDER_CREATE_OR_ASSOCIATE = 20 * 1000 * SEVENTY_PERCENT_MODIFIER; // 20 seconds


const getTransferTiming = (fileCount: number): number => {
    return TRANSFERING_DATA_TIME_PER_1000_IMAGES * fileCount / 1000.0;
};

const getConfiguringPatientTime = (fileCount: number): number => {
    return CONFIGURING_PATIENT
};

const getProcessingTime = (fileCount: number): number => {
    return PROCESSING_DATA_PER_1000_IMAGES * fileCount / 1000.0;
};

const getOrderCreateOrAssociateTime = (fileCount: number): number => {
    return ORDER_CREATE_OR_ASSOCIATE;
};


const TIMINGS_PER_WORKFLOW_TYPE = {
    organizationLevelUpload: [
        getTransferTiming,
        getProcessingTime
    ],
    trialLevelUpload: [
        getTransferTiming,
        getProcessingTime,
        getConfiguringPatientTime,
        getOrderCreateOrAssociateTime
    ],
    blindedTrialLevelUpload: [
        getTransferTiming,
        getProcessingTime,
        getConfiguringPatientTime,
        getOrderCreateOrAssociateTime
    ],
    deidentifiedTrialLevelUpload: [
        getTransferTiming,
        getConfiguringPatientTime,
        getProcessingTime,
        getOrderCreateOrAssociateTime
    ],
    orderLevelUpload: [
        getTransferTiming,
        getProcessingTime,
        getOrderCreateOrAssociateTime
    ],
    blindedOrderLevelUpload: [
        getTransferTiming,
        getProcessingTime,
        getOrderCreateOrAssociateTime
    ],
    deidentifiedOrderLevelUpload: [
        getTransferTiming,
        getProcessingTime,
        getOrderCreateOrAssociateTime
    ]
};


const getApproximateStepUploadTimeForStep = (uploadInput: UploadInput, fileCount: number, step: number): number => {
    const { uploadLevel, isBlindedTrial, deidentifying } = uploadInput;

    if (uploadLevel === UploadLevel.ORGANIZATION) {
        // Org
        return TIMINGS_PER_WORKFLOW_TYPE.organizationLevelUpload[step - 1](fileCount);
    }

    if (uploadLevel === UploadLevel.TRIAL) {
        if (deidentifying) {
            // deid trial
            return TIMINGS_PER_WORKFLOW_TYPE.deidentifiedTrialLevelUpload[step - 1](fileCount);
        }
        if (isBlindedTrial) {
            // blinded trial
            return TIMINGS_PER_WORKFLOW_TYPE.blindedTrialLevelUpload[step - 1](fileCount);
        }

        // Normal trial
        return TIMINGS_PER_WORKFLOW_TYPE.trialLevelUpload[step - 1](fileCount);
    }

    if (uploadLevel === UploadLevel.ORDER) {
        if (deidentifying) {
            // deid order
            return TIMINGS_PER_WORKFLOW_TYPE.deidentifiedOrderLevelUpload[step - 1](fileCount);
        }
        if (isBlindedTrial) {
            // blinded order
            return TIMINGS_PER_WORKFLOW_TYPE.blindedOrderLevelUpload[step - 1](fileCount);
        }

        // Normal trial
        return TIMINGS_PER_WORKFLOW_TYPE.deidentifiedOrderLevelUpload[step - 1](fileCount);
    }

    return FALLBACK;
};

/**
 * Slow down progress as we hit near 100.
 * 70-99 takes ~4x as long as 0-70.
 */
const modifyProgressTickTime = (stepTime: number, percent: number): number => {
    // Catch early exit first.
    if (percent < 70) {
        return stepTime;
    }


    // Slowdown from 70 to 99.
    if (percent >= 97) {
        return 32 * stepTime;
    }

    if (percent >= 91) {
        return 16 * stepTime;
    }

    if (percent >= 86) {
        return 8 * stepTime;
    }

    if (percent >= 80) {
        return 4 * stepTime;
    }

    return 2 * stepTime;

}

export { getApproximateStepUploadTimeForStep, modifyProgressTickTime };