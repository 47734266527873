import { parseDicom, readPart10Header } from "dicom-parser"
// @ts-ignore We don't have types for dcmjs
import dcmjs from 'dcmjs'
import { ExtendedDicomFile } from "./types"

type GetDicomHeaderReturnType = {
  StudyInstanceUID: string,
  header: any
}

type StudyAndSOPInstanceUIDs = {
  StudyInstanceUID: string;
  SOPInstanceUID: string;
}

/**
 * Range reads to only pull the first few bytes of a dicom file to disk,
 * in order to check if they are valid dicom files.
 */
const isDicom = async (file: File): Promise<boolean> => {
  // Read only the first mandatory fields in the header:
  // === 394 bytes
  return new Promise((resolve) => {
    const fileReader = new FileReader()

    if (file.size < 394) {
      // Doesn't even contain P10 header.
      resolve(false)
      return;
    }

    fileReader.onload = (evt: ProgressEvent<FileReader>): void => {
      const data = evt.target?.result

      if (!(data instanceof ArrayBuffer)) {
        resolve(false)
        return;
      }

      const uInt8View = new Uint8Array(data as ArrayBuffer)

      try {
        readPart10Header(uInt8View, {
          untilTag: 'x00020000'
        })

        resolve(true)
      } catch (e) {
        resolve(false)
      }
    }

    fileReader.onerror = (): void => {
      resolve(false)
    }

    const fileHeadersOnly = file.slice(0, 394) // TODO work out this number

    fileReader.readAsArrayBuffer(fileHeadersOnly)
  })
}


/**
 * Gets the naturalized DICOM header for a file.
 */
const getDicomHeader = async (file: ExtendedDicomFile): Promise<GetDicomHeaderReturnType> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()

    const StudyInstanceUID = file.StudyInstanceUID

    fileReader.onload = (evt: ProgressEvent<FileReader>): void => {
      const data = evt.target?.result


      if (!(data instanceof ArrayBuffer)) {
        reject('unparsable data')
      }

      const dicomData = dcmjs.data.DicomMessage.readFile(
        data,
        {
          untilTag: "7FE00010",
          includeUntilTagValue: false
        }
      );

      let naturalizedDataset = dcmjs.data.DicomMetaDictionary.naturalizeDataset(
        dicomData.dict
      );

      naturalizedDataset._meta = dcmjs.data.DicomMetaDictionary.namifyDataset(
        dicomData.meta
      );

      // Remove pixel data so we don't keep it in memory.
      delete naturalizedDataset.PixelData

      resolve({ StudyInstanceUID, header: naturalizedDataset })
    }

    fileReader.readAsArrayBuffer(file)
  })
}

/**
 * Pulls the study instance UID from a file.
 * 
 * Note we could probably optimise it to not have to pull in the full buffer if we did multiple reads.
 * 
 */
const getStudyAndSOPInstanceUIDs = async (file: File): Promise<StudyAndSOPInstanceUIDs> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()

    fileReader.onload = (evt: ProgressEvent<FileReader>): void => {
      const data = evt.target?.result


      if (!(data instanceof ArrayBuffer)) {
        reject('unparsable data')
      }

      const uInt8View = new Uint8Array(data as ArrayBuffer)

      const dataSet = parseDicom(uInt8View, {
        untilTag: 'x00020000'
      })

      const StudyInstanceUID = dataSet.string('x0020000d')
      const SOPInstanceUID = dataSet.string('x00080018')

      if (StudyInstanceUID === undefined || SOPInstanceUID === undefined) {
        reject('No StudyInstanceUID')
        return;
      }

      resolve({ StudyInstanceUID, SOPInstanceUID })
    }

    fileReader.readAsArrayBuffer(file)
  })
}

export { isDicom, getStudyAndSOPInstanceUIDs, getDicomHeader }
export type { GetDicomHeaderReturnType }