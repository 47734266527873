import React from "react";
import "./UserInput.css"
import UserInputFooterIdentified from "./UserInputFooterIdentified";

const OrganizationLevelInput = () => {
    return (
        <div className="user-input-layout">
            <UserInputFooterIdentified
                canUpload={true}
                userDefinedPatientInfo={{}}
            />
        </div>
    );
}


export default OrganizationLevelInput