import { useMemo, useState } from "react"
import { requestQueue } from "../requestQueue"
import axios from 'axios'
import { ExtendedDicomFile } from "../types"

/**
 * Given a single file, uploads it to a signed URL.
 */
const uploadOneFileToSignedUrl = (file: File, signedUrl: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
  
      fileReader.onload = (evt: ProgressEvent<FileReader>): void => {
        const data = evt.target?.result
    
        if (!data) {
          reject('unparsable data')
        }
    
        axios.put(signedUrl,
          data,
          {
            headers: {
              'Content-Type': file.type
            }
          }
        )
        .then(() => resolve())
        .catch((err) => reject(err))
      }
    
      fileReader.readAsArrayBuffer(file)
    })
}

const ATTACHMENTS = 'ATTACHMENTS'


type UploadFileResult = {
  success: boolean,
  resource: string,
  file: File | ExtendedDicomFile,
  signedUrl: string
}

/**
 * Returns an API for uploading files to signedURLs in a queue, and reactive variables to track progress.
 */
const useFileUploadQueue = () => {
    const [totalUploadCount, setTotalUploadCount] = useState<number>(0)
    const [fileGroupUploadCounts, setFileGroupUploadCounts] = useState<Record<string,number>>({})
    const [completedToNotify, setCompletedToNotify] = useState<string[]>([])

    const cancelUpload = useMemo(() => {
      return () => {
        requestQueue.clear()
        setTotalUploadCount(0)
        setFileGroupUploadCounts({})
        setCompletedToNotify([])
      }
  },[])
  
    const uploadFileAndUpdateCount = useMemo(() => {
      return (resource: string, file: File | ExtendedDicomFile, signedUrl: string): Promise<UploadFileResult> =>  {
        return new Promise((resolve) => {
          requestQueue.add(async () => uploadOneFileToSignedUrl(file, signedUrl)).then(() => {
            let fileGroup: string
    
            if ((file as ExtendedDicomFile).StudyInstanceUID) {
              fileGroup = (file as ExtendedDicomFile).StudyInstanceUID
            } else {
              fileGroup = ATTACHMENTS
            }
          
            setFileGroupUploadCounts((previousCounts) => {
              const newCounts: Record<string,number> = {}
      
              Object.keys(previousCounts).forEach(fileGroup => {
                newCounts[fileGroup] = previousCounts[fileGroup]
              })
      
              if (!newCounts[fileGroup]) {
                newCounts[fileGroup] = 0
              }
      
              newCounts[fileGroup] += 1
      
              return newCounts
            })
            setTotalUploadCount((previousCount) => previousCount + 1)
            setCompletedToNotify((list) => [...list, resource])
          
            resolve({
              success: true,
              resource,
              file,
              signedUrl
            })
          }).catch(() => {
            resolve({
              success: false,
              resource,
              file,
              signedUrl
            })
          })
        })
    }
  }, [] )

    return {
      cancelUpload,
      totalUploadCount,
      fileGroupUploadCounts,
      completedToNotify,
      uploadFileAndUpdateCount
    }
  }

  export { useFileUploadQueue }
  export type { UploadFileResult }