import { auth0Axios } from '../../auth/auth0Axios';
import { GuestUploader as GuestUploaderType } from '../../../../sharedTypes/bucketUploadTypes';
import { TrialResponse, SitePatientsForPatientResponse, PatientForMrnResponse, DeidentifiedPatientResponse, OrderResponse, SubjectIdAvailableResponse, HelpDeskEmailAddressResponse } from '../../../../sharedTypes/wmsQueryTypes'
import { UploadInput } from '../../providers/FileStateProvider';

const searchForPatientByMrn = async (
  uploadInput: UploadInput,
  mrn: string,
  siteId: string
): Promise<PatientForMrnResponse | undefined> => {
  const { guestUploader } = uploadInput

  if (guestUploader) {
    if (guestUploader.temporary) {
      return fetchPatientByMrnGuest(mrn, siteId, guestUploader.guestUploaderId)
    }

    return;
  }

  return fetchPatientByMrnLoggedIn(mrn, siteId)
}

const searchForDeidentifiedPatient = async (siteId: string, trialId: string, originalMedicalRecordNumber: string, guestUploader?: UploadInput['guestUploader']): Promise<DeidentifiedPatientResponse | undefined> => {
  if (guestUploader) {
    if (guestUploader.temporary) {
      return fetchDeidentifiedPatientGuest(siteId, trialId, originalMedicalRecordNumber, guestUploader.guestUploaderId)
    }

    return;
  }

  return fetchDeidentifiedPatientLoggedIn(siteId, trialId, originalMedicalRecordNumber)
}

const fetchSubjectIdAvailable = async (trialId: string, subjectId: string, guestUploader?: UploadInput['guestUploader']): Promise<boolean | undefined> => {
  if (guestUploader) {
    if (guestUploader.temporary) {
      return fetchSubjectIdAvailableGuest(trialId, subjectId, guestUploader.guestUploaderId)
    }
  }

  return fetchSubjectIdAvailableLoggedIn(trialId, subjectId)
}

const fetchGuestUploader = (guestUploaderId: string): Promise<{ guestUploader: GuestUploaderType, helpDeskEmailAddress?: string } | undefined> => {
  return new Promise((resolve) => {
    auth0Axios.get(`/api/guest-uploader/${guestUploaderId}`).then(result => {
      const guestUploader = result.data

      resolve(guestUploader)
    }).catch((error) => {
      console.error(error);
      resolve(undefined)
    })
  })
}

const fetchTrialLoggedIn = (trialId: string): Promise<TrialResponse | undefined> => {
  return new Promise((resolve) => {
    auth0Axios.get(`/api/wms/trial/${trialId}`).then(result => {
      const trial: TrialResponse = result.data

      resolve(trial)
    }).catch((error) => {
      resolve(undefined)
    })
  })
}

const fetchTrialGuest = (trialId: string, guestUploaderId: string): Promise<TrialResponse | undefined> => {
  return new Promise((resolve) => {
    auth0Axios.get(`/api/wms/trial/${trialId}/guest/${guestUploaderId}`).then(result => {
      const trial: TrialResponse = result.data

      resolve(trial)
    }).catch((error) => {
      console.error(error);
      resolve(undefined)
    })
  })
}

const fetchPatientLoggedIn = (trialId: string, trialPatientId: string): Promise<SitePatientsForPatientResponse | undefined> => {
  return new Promise((resolve) => {
    auth0Axios.get(`/api/wms/trials/${trialId}/trialPatients/${trialPatientId}`).then(result => {
      const patient: SitePatientsForPatientResponse = result.data

      resolve(patient)
    }).catch((error) => {
      console.error(error);
      resolve(undefined)
    })
  })
}

const fetchPatientGuest = (trialId: string, trialPatientId: string, guestUploaderId: string): Promise<SitePatientsForPatientResponse | undefined> => {
  return new Promise((resolve) => {
    auth0Axios.get(`/api/wms/trials/${trialId}/trialPatients/${trialPatientId}/guest/${guestUploaderId}`).then(result => {
      const patient: SitePatientsForPatientResponse = result.data

      resolve(patient)
    }).catch((error) => {
      console.error(error);
      resolve(undefined)
    })
  })
}

const fetchPatientByMrnLoggedIn = (mrn: string, siteId: string): Promise<PatientForMrnResponse | undefined> => {
  return new Promise((resolve) => {
    auth0Axios.get(`/api/wms/patient-by-mrn/mrn/${mrn}/siteId/${siteId}`).then(result => {
      const patient: PatientForMrnResponse = result.data

      resolve(patient)
    }).catch((error) => {
      console.error(error);
      resolve(undefined)
    })
  })
}

const fetchPatientByMrnGuest = (mrn: string, siteId: string, guestUploaderId: string): Promise<PatientForMrnResponse | undefined> => {
  return new Promise((resolve) => {
    auth0Axios.get(`/api/wms/patient-by-mrn/mrn/${mrn}/siteId/${siteId}/guest/${guestUploaderId}`).then(result => {
      const patient: PatientForMrnResponse = result.data

      resolve(patient)
    }).catch((error) => {
      console.error(error);
      resolve(undefined)
    })
  })
}

const fetchDeidentifiedPatientLoggedIn = (siteId: string, trialId: string, originalMedicalRecordNumber: string): Promise<DeidentifiedPatientResponse | undefined> => {
  return new Promise((resolve) => {
    auth0Axios.get(`/api/wms/deidentified-patient/site/${siteId}/trial/${trialId}/mrn/${originalMedicalRecordNumber}/`).then(result => {
      const patient: DeidentifiedPatientResponse = result.data

      resolve(patient)
    }).catch((error) => {
      console.error(error);
      resolve(undefined)
    })
  })
}

const fetchDeidentifiedPatientGuest = (siteId: string, trialId: string, originalMedicalRecordNumber: string, guestUploaderId: string): Promise<DeidentifiedPatientResponse | undefined> => {
  return new Promise((resolve) => {
    auth0Axios.get(`/api/wms/deidentified-patient/site/${siteId}/trial/${trialId}/mrn/${originalMedicalRecordNumber}/guest/${guestUploaderId}`).then(result => {
      const patient: DeidentifiedPatientResponse = result.data

      resolve(patient)
    }).catch((error) => {
      console.error(error);
      resolve(undefined)
    })
  })
}

const fetchOrderForTrialPatientLoggedIn = (trialId: string, trialPatientId: string, orderId: string): Promise<OrderResponse | undefined> => {
  return new Promise((resolve) => {
    auth0Axios.get(`/api/wms/trial/${trialId}/trialPatient/${trialPatientId}/order/${orderId}`).then((result) => {
      const orderResponse: OrderResponse = result.data;

      resolve(orderResponse)
    }).catch((error) => {
      resolve(undefined)
    })
  })
}

const fetchOrderForTrialPatientGuest = (trialId: string, trialPatientId: string, orderId: string, guestUploaderId: string): Promise<OrderResponse | undefined> => {
  return new Promise((resolve) => {
    auth0Axios.get(`/api/wms/trial/${trialId}/trialPatient/${trialPatientId}/order/${orderId}/guest/${guestUploaderId}`).then((result) => {
      const orderResponse: OrderResponse = result.data;

      resolve(orderResponse)
    }).catch((error) => {
      console.error(error);
      resolve(undefined)
    })
  })
}

const fetchSubjectIdAvailableLoggedIn = (trialId: string, subjectId: string): Promise<boolean | undefined> => {
  return new Promise((resolve) => {
    auth0Axios.get(`/api/wms/subject-id-available/trial/${trialId}/subjectId/${subjectId}`).then((result) => {
      const response: SubjectIdAvailableResponse = result.data;

      resolve(response.available);
    }).catch((error) => {
      console.error(error);
      resolve(undefined)
    })
  })
}

const fetchSubjectIdAvailableGuest = (trialId: string, subjectId: string, guestUploaderId: string): Promise<boolean | undefined> => {
  return new Promise((resolve) => {
    auth0Axios.get(`/api/wms/subject-id-available/trial/${trialId}/subjectId/${subjectId}/guest/${guestUploaderId}`).then((result) => {
      const response: SubjectIdAvailableResponse = result.data;

      resolve(response.available);
    }).catch((error) => {
      console.error(error);
      resolve(undefined)
    })
  })
}

const fetchHelpDeskEmailAddressLoggedIn = (): Promise<string | undefined> => {
  return new Promise((resolve) => {
    auth0Axios.get(`/api/wms/help-desk-email-address`).then((result) => {
      const response: HelpDeskEmailAddressResponse = result.data;

      resolve(response.helpDeskEmailAddress);
    }).catch((error) => {
      console.error(error);
      resolve(undefined)
    })
  })
}

export {
  fetchGuestUploader,
  fetchTrialLoggedIn,
  fetchTrialGuest,
  fetchPatientLoggedIn,
  fetchPatientGuest,
  fetchOrderForTrialPatientLoggedIn,
  fetchOrderForTrialPatientGuest,
  searchForPatientByMrn,
  searchForDeidentifiedPatient,
  fetchSubjectIdAvailable,
  fetchHelpDeskEmailAddressLoggedIn
}