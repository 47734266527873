import { useEffect } from "react"
import axios from "axios"
import throttle from 'lodash.throttle'
import { CompleteResponse } from "../../../../sharedTypes/bucketUploadTypes"

/**
 * Updates the backend periodically with the list of uploaded files.
 */
const throttledUploaderStatusUpload = throttle((uploaderInstanceId: string, completed: string[]) => {
  axios.patch('/api/uploader/instance/update-status', { uploaderInstanceId, completed })
}, 5000, {leading: true, trailing: true})

const postCompleteAndTellWindowOpener = async (uploaderInstanceId: string): Promise<void> => {
  const result = await axios.post('/api/uploader/instance/complete', { uploaderInstanceId })

  const response: CompleteResponse = result.data

  const { workflowId } = response

  // Post workflowId to window opener.

  const windowOpener = window.opener

  // Note we may have no window opener if the uploader was accessed directly.
  if (!windowOpener) {
    return
  }

  windowOpener.postMessage({
    workflowId
  })
}


const useOnUploadComplete = (uploaderInstanceId: string | undefined, completedToNotify: string[], uploadedFileCount: number, totalFileCount: number) => {
    useEffect(() => {
      if (!uploaderInstanceId || !completedToNotify.length || !totalFileCount) {
        return
      }
  
      if (uploadedFileCount === totalFileCount) {
        postCompleteAndTellWindowOpener(uploaderInstanceId)
  
        return
      }
  
      throttledUploaderStatusUpload(uploaderInstanceId, completedToNotify)
    }, [completedToNotify, uploaderInstanceId, totalFileCount, uploadedFileCount])
  }


  export { useOnUploadComplete }