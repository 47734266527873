import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

/* Providers */
import {
  AuthProvider,
} from '../providers';

function AuthWrapper({Component, siteUrl})  {
    const navigate = useNavigate();
    
    const onRedirectCallback = (appState) => {
        navigate(
            appState && appState.returnTo
              ? appState.returnTo
              : window.location.pathname
          );
    }

    return (
        <Auth0Provider
            domain={window.config.auth0.domain}
            clientId={window.config.auth0.clientId}
            authorizationParams = {{
                redirect_uri: window.location.origin,
                audience: window.config.auth0.audience,
                scope: window.config.auth0.scope,
                org: siteUrl
            }}
            useRefreshTokens={true}
            onRedirectCallback={onRedirectCallback}
        >
            <AuthProvider>
                <Component />
            </AuthProvider>
        </Auth0Provider>
    );
}

export default AuthWrapper;
