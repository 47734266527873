import React from "react";
import { useFileState } from "../../providers/FileStateProvider";
import DeidentifiedPatientInfoInput from "../PatientInfoInput/DeidentifiedPatientInfoInput";
import "./UserInput.css";
import UserInputFooterBlinded from "./UserInputFooterBlinded";
import AttachmentsWithDropDown from "./AttachmentsWithDropDown";

const BlindedOrderLevelInput = () => {
    const { uploadInput } = useFileState()

    const subjectId = uploadInput?.patient?.subjectId;

    return (
        <div className="user-input-layout">
            <DeidentifiedPatientInfoInput
                subjectIdUnavailable={false}
                defaultDeidentifiedSubjectId={subjectId}
            />
            <AttachmentsWithDropDown />
            <UserInputFooterBlinded
                canUpload={true}
                defaultDeidentifiedSubjectId={subjectId}
            />
        </div>
    );
}


export default BlindedOrderLevelInput;