import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useFileState } from '../providers/FileStateProvider';
import SiteUploaderSelectData from './SiteUploader/SiteUploaderSelectData';
import { StateCode } from '../providers/FileStateProvider';
import UploadStatus from './Shared/UploadStatus';
import PageWrapper from './PageWrapper';
import {
  fetchTrialLoggedIn,
  fetchPatientLoggedIn,
  fetchOrderForTrialPatientLoggedIn,
  fetchHelpDeskEmailAddressLoggedIn,
} from '../lib/api/wms';
import { auth0TokenPromise } from '../providers/AuthProvider';
import { DeidentificationProtocol } from '../../../sharedTypes/deidentifyTypes';
import { OrderResponse, SitePatientsForPatientResponse, TrialResponse } from '../../../sharedTypes/wmsQueryTypes';
import LoadingSpinner from './Shared/LoadingSpinner';
import UserInput from './UserInput/UserInput';

function SiteUploader() {
  const { trial_id, trial_patient_id, order_id } = useParams();

  const {
    state,
    api,
    uploadInput,
  } = useFileState();

  const [urlSearchParams] = useSearchParams();
  const [invalidMessage, setInvalidMessage] = useState<string | undefined>();
  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    // Reset the state on page load
    api.resetState();

    const initialise = async () => {
      /**
       * Await our token promise we can use API auth Auth0 authentication.
       */
      await auth0TokenPromise;

      let trial: TrialResponse | undefined = undefined;
      let patient: SitePatientsForPatientResponse | undefined = undefined;
      let order: OrderResponse | undefined = undefined;

      const helpDeskEmailAddress = await fetchHelpDeskEmailAddressLoggedIn();

      if (trial_id !== undefined) {
        trial = await fetchTrialLoggedIn(trial_id);

        if (!trial) {
          setInvalidMessage('Trial does not exist.');
          return;
        }
      }

      if (
        trial_id !== undefined &&
        trial_patient_id !== undefined &&
        order_id !== undefined
      ) {
        order = await fetchOrderForTrialPatientLoggedIn(
          trial_id,
          trial_patient_id,
          order_id
        );

        if (!order) {
          // Order does not exist for patient.
          setInvalidMessage('Order for patient in trial does not exist.');
          return;
        }
      }

      if (trial_id !== undefined && trial_patient_id !== undefined) {
        patient = await fetchPatientLoggedIn(trial_id, trial_patient_id);

        if (!patient) {
          setInvalidMessage('Patient does not exist.');
          return;
        }
      }

      let deidentifying: boolean = false;
      let trialAnonymizationProtocol: DeidentificationProtocol | undefined = undefined;
      let redactBurnedInPHI: boolean = false

      if (trial) {
        deidentifying = trial.anonymizationProtocol.deidentifying;
        redactBurnedInPHI = trial.anonymizationProtocol.redactBurnedInPHI;
        trialAnonymizationProtocol = trial.anonymizationProtocol.deidentificationProtocol
      }

      // Set state
      api.setUploadInput({
        helpDeskEmailAddress,
        trial_id,
        trial_patient_id,
        order_id,
        trial,
        patient,
        order,
        deidentifying,
      });
      api.setMandatoryDeidentifyList(
        trialAnonymizationProtocol
      );
      api.setMandatoryRedactBurnedInPHI(
        redactBurnedInPHI
      );

      setInitialized(true);
    };

    initialise();

    // We really only want this to happen once.
    // eslint-disable-next-line
  }, [urlSearchParams]);

  if (invalidMessage) {
    return (
      <PageWrapper>
        <p>{invalidMessage}</p>
      </PageWrapper>
    );
  }

  if (!initialized) {
    return (
      <PageWrapper>
        <LoadingSpinner />
      </PageWrapper>
    );
  }

  if (state === StateCode.SELECT_DATA) {
    return (
      <PageWrapper
        trialId={trial_id}
        trial={uploadInput.trial}
        patient={uploadInput.patient}
        order={uploadInput.order}
      >
        <SiteUploaderSelectData
          trial_id={trial_id}
          uploadInstructions={uploadInput.trial?.uploadInstructions}
        />
      </PageWrapper>
    );
  }

  if (state === StateCode.DEID_AND_ADD_ATTACHMENTS) {
    return (
      <PageWrapper
        trialId={trial_id}
        trial={uploadInput.trial}
        patient={uploadInput.patient}
        order={uploadInput.order}
      >
        <UserInput />
      </PageWrapper>
    );
  }

  if (state === StateCode.UPLOAD_STATUS) {
    return (
      <PageWrapper
        trialId={trial_id}
        trial={uploadInput.trial}
        patient={uploadInput.patient}
        order={uploadInput.order}
      >
        <UploadStatus />
      </PageWrapper>
    );
  }

  return <div className="site-uploader" />;
}

export default SiteUploader;
