import React, { useEffect, useState } from "react";
import { UploadInput, useFileState } from "../../providers/FileStateProvider";
import { fetchSubjectIdAvailable, searchForDeidentifiedPatient } from "../../lib/api/wms";
import { getDicomMrn } from "../../providers/FileStateProvider";
// @ts-ignore
import debounce from 'lodash.debounce';
import DeidentifiedPatientInfoInput from "../PatientInfoInput/DeidentifiedPatientInfoInput";
import "./UserInput.css"
import UserInputFooterBlinded from "./UserInputFooterBlinded";
import AttachmentsWithDropDown from "./AttachmentsWithDropDown";
import LoadingSpinner from "../Shared/LoadingSpinner";

const subjectIdToUse = (
    defaultDeidentifiedSubjectId: string | undefined,
    userDefinedDeidentifiedSubjectId: string | undefined
): string | undefined => {
    if (userDefinedDeidentifiedSubjectId !== undefined && userDefinedDeidentifiedSubjectId.length) {
        return userDefinedDeidentifiedSubjectId;
    }

    if (defaultDeidentifiedSubjectId !== undefined && defaultDeidentifiedSubjectId.length) {
        return defaultDeidentifiedSubjectId;
    }
}

const verifyUpload = async (
    trialId: string,
    defaultDeidentifiedSubjectId: string | undefined,
    userDefinedDeidentifiedSubjectId: string | undefined,
    guestUploader?: UploadInput['guestUploader']
): Promise<{ canUpload: boolean, subjectIdUnavailable: boolean }> => {
    // Verify subject Id.
    const subjectId = subjectIdToUse(defaultDeidentifiedSubjectId, userDefinedDeidentifiedSubjectId);

    if (!subjectId) {
        return { canUpload: false, subjectIdUnavailable: false };
    }

    // If its the default, we don't need to check if its available since its mandated.
    if (defaultDeidentifiedSubjectId !== undefined && defaultDeidentifiedSubjectId.length) {
        return { canUpload: true, subjectIdUnavailable: false };
    }

    // In the guest uploader case we let them put whatever they want and it will be reconcilled on the backend.
    if (guestUploader) {
        return { canUpload: true, subjectIdUnavailable: false }
    }

    const subjectIdAvailable = await fetchSubjectIdAvailable(trialId, subjectId, guestUploader);

    if (subjectIdAvailable) {
        return { canUpload: true, subjectIdUnavailable: false };
    }

    return { canUpload: false, subjectIdUnavailable: true };
}

type BlindedTrialLevelInputProps = {
    trialId: string;
    siteId: string;
}

const BlindedTrialLevelInput = ({ trialId, siteId }: BlindedTrialLevelInputProps) => {
    const {
        studyMetadata,
        uploadInput
    } = useFileState()

    /**
     * Deidentified Patient
     */
    const [defaultDeidentifiedSubjectId, setDefaultDeidentifiedSubjectId] = useState<string | undefined>(uploadInput?.patient?.subjectId);
    const [userDefinedDeidentifiedSubjectId, setUserDefinedDeidentifiedSubjectId] = useState<string | undefined>(undefined);

    const [canUpload, setCanUpload] = useState<boolean>(false);
    const [subjectIdUnavailable, setSubjectIDUnavailable] = useState<boolean>(false);
    const [loading, setIsLoading] = useState<boolean>(true);

    /**
     * Verify if all input data is sufficient for upload
     */
    useEffect(() => {
        // Invalidate until we verify.
        setCanUpload(false);

        const verifyUploadAndSetNewStatus = async () => {
            const verifyUploadResult = await verifyUpload(
                trialId,
                defaultDeidentifiedSubjectId,
                userDefinedDeidentifiedSubjectId,
                uploadInput.guestUploader
            );

            setCanUpload(verifyUploadResult.canUpload);
            setSubjectIDUnavailable(verifyUploadResult.subjectIdUnavailable);
        }

        verifyUploadAndSetNewStatus();
    }, [
        uploadInput,
        defaultDeidentifiedSubjectId,
        userDefinedDeidentifiedSubjectId
    ])

    /**
     * Debounce this as we make server calls. Only make the call once after typing has stopped for some time.
     */
    const debounceUpdateDeidentifiedSubjectId = debounce(setUserDefinedDeidentifiedSubjectId, 200);

    const setNewUserDefinedDeidentifiedSubjectId = (subjectId: string) => {
        debounceUpdateDeidentifiedSubjectId(subjectId);
    }

    const patientMatching = async (): Promise<void> => {
        // Check if we have a matching SubjectID from the siteId, trialId and original MRN.
        const originalMedicalRecordNumber = getDicomMrn(studyMetadata)
        const result = await searchForDeidentifiedPatient(siteId, trialId, originalMedicalRecordNumber);
        const subjectId = result ? result.subjectId : undefined;

        setDefaultDeidentifiedSubjectId(subjectId);
        setIsLoading(false);
    }

    /**
     * Search for the patient based on the MRN on page load.
     * Don't do this for blind trial uploads, we will always create a new patient.
     */
    useEffect(() => {
        if (uploadInput.guestUploader) {
            setIsLoading(false);
        } else {
            patientMatching();
        }
    }, [])

    if (loading) {
        return <div><LoadingSpinner /></div>
    }

    return (
        <div className="user-input-layout">
            <DeidentifiedPatientInfoInput
                subjectIdUnavailable={subjectIdUnavailable}
                defaultDeidentifiedSubjectId={defaultDeidentifiedSubjectId}
                setNewUserDefinedDeidentifiedSubjectId={setNewUserDefinedDeidentifiedSubjectId}
            />
            <AttachmentsWithDropDown />
            <UserInputFooterBlinded
                canUpload={canUpload && !subjectIdUnavailable}
                defaultDeidentifiedSubjectId={defaultDeidentifiedSubjectId}
                userDefinedDeidentifiedSubjectId={userDefinedDeidentifiedSubjectId}
                selectedSiteId={siteId}
            />
        </div>
    );
}


export default BlindedTrialLevelInput