import React, { useEffect, useState } from 'react';
import { StudyDateOverride, UploadLevel, useFileState } from '../../providers/FileStateProvider';
import './SelectStudies.css';
import '../NextPrevButton.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { formatDate } from '../../lib/format';
import { dicomDateIncludingTimeZone } from '../../lib/dicomDateIncludingTimezone';
import SiteSelector from './SiteSelector';
import { SITE_URL_LOCAL_STORAGE_KEY } from '../../auth/localStorageKeys';
import DateInput from '../Anonymization/input/DateInput';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { getSupportEmail } from '../../lib/getSupportEmail';

type StudyEntry = {
  StudyInstanceUID: string;
  PatientID: string;
  StudyDate?: string;
  Modality: string;
  StudyDescription: string;
  fileCount: number;
};

const getNewOrderScanMessage = (newOrderScans: string[]): string => {
  if (newOrderScans.length === 1) {
    return `Study ${newOrderScans} is for a scan date not present on the order. If you proceed with upload these scan dates will be added to the order.`
  }

  return `Studies ${newOrderScans.join(', ')} are for scan dates not present on the order. If you proceed with upload these scan dates will be added to the order.`
}

const getUnsupportedFilesText = (unsupportedFileNames: string[]): string => {
  const numUnsupportedFiles = unsupportedFileNames.length;

  if (numUnsupportedFiles === 1) {
    return `${unsupportedFileNames[0]}`;
  } else if (numUnsupportedFiles === 2) {
    return `${unsupportedFileNames[0]}, ${unsupportedFileNames[1]}`;
  }

  return `${unsupportedFileNames[0]}, ${unsupportedFileNames[1]} and ${numUnsupportedFiles - 2
    } more files`;
};

const OrderUploadSiteInactiveWarning = ({ helpDeskEmailAddress }: { helpDeskEmailAddress?: string }) => {
  const supportEmail = getSupportEmail(helpDeskEmailAddress);

  return (
    <p className="order-studies-warning">
      {`Site associated with order is inactive, please contact `} <a href={`mailto:${supportEmail}`}>{supportEmail}</a>.
    </p>
  );
};


const SITE_SELECTION_LOCAL_STORAGE_KEY = 'SITE_SELECTION_LOCAL_STORAGE_KEY';

type CachedDefaultSiteIDMap = Record<string, number>;

const getCachedSiteIdForOrganization = (): number | undefined => {
  const siteUrl = localStorage.getItem(SITE_URL_LOCAL_STORAGE_KEY);
  const map = localStorage.getItem(SITE_SELECTION_LOCAL_STORAGE_KEY);

  if (siteUrl === null || map === null) {
    return undefined;
  }

  const mapObject: CachedDefaultSiteIDMap = JSON.parse(map);

  const siteId = mapObject[siteUrl]

  if (siteId === undefined) {
    return;
  }

  return Number(siteId);
}

const setCachedSiteIdForOrganization = (siteId: number): void => {
  const siteUrl = localStorage.getItem(SITE_URL_LOCAL_STORAGE_KEY);
  const map = localStorage.getItem(SITE_SELECTION_LOCAL_STORAGE_KEY);

  if (siteUrl === null) {
    return undefined;
  }

  let mapObject: CachedDefaultSiteIDMap;

  if (map) {
    mapObject = JSON.parse(map)
  } else {
    mapObject = {};
  }

  mapObject[siteUrl] = siteId;

  localStorage.setItem(SITE_SELECTION_LOCAL_STORAGE_KEY, JSON.stringify(mapObject))
}

const SelectStudies = () => {
  const [newOrderScanStudies, setNewOrderScanStudies] =
    useState<string[]>([]);
  const { api, studyMetadata, unsupportedFileNames, uploadInput } =
    useFileState();
  let unsupportedText = unsupportedFileNames.length
    ? getUnsupportedFilesText(unsupportedFileNames)
    : undefined;

  const [studyEntries, setStudyEntries] = useState<StudyEntry[]>([]);
  const [differentMrns, setDifferentMrns] = useState<boolean>(false);

  const [selectedSiteId, setSelectedSiteId] = useState<number | undefined>(undefined);
  const [hasMissingStudyDates, setHasMissingStudyDates] = useState<boolean>(false);
  const [studyDatesFilled, setStudyDatesFilled] = useState<boolean>(true);
  const [studyDateOverride, setStudyDateOverride] = useState<StudyDateOverride>({});
  const [orderUploadSiteInactive, setOrderUploadSiteInactive] = useState<boolean>(false);


  useEffect(() => {
    if (uploadInput.uploadLevel === UploadLevel.ORDER && uploadInput.trial && uploadInput.order) {
      // Check if the site associated with the order is inactive.
      const orderSiteId = uploadInput.order.siteId;
      const trialSite = uploadInput.trial.trialSites.find(ts => ts.siteId === orderSiteId);

      if (!trialSite) {
        setOrderUploadSiteInactive(true);
        throw new Error('trial site not found for order');

      }

      if (!trialSite.active) {
        setOrderUploadSiteInactive(true);
      }
    }
  })


  useEffect(() => {
    if (!hasMissingStudyDates) {
      setStudyDatesFilled(true);
    }

    let newStudyDatesFilled = true;

    studyEntries.forEach((entry) => {
      const { StudyInstanceUID, StudyDate } = entry;

      if (!StudyDate && !studyDateOverride[StudyInstanceUID]) {
        newStudyDatesFilled = false;
      }
    })
    setStudyDatesFilled(newStudyDatesFilled)
  }, [hasMissingStudyDates, studyDateOverride, studyEntries])


  useEffect(() => {
    if (uploadInput.uploadLevel === UploadLevel.TRIAL && uploadInput.trial && !uploadInput.guestUploader) {
      const siteId = getCachedSiteIdForOrganization();

      // Only used the cached SiteId if it corresponds to a site available on this trial for this user.
      if (siteId && uploadInput.trial.trialSites.some(ts => ts.siteId === siteId && ts.active)) {
        api.setSiteId(String(siteId));
        setSelectedSiteId(siteId);
      }
    }
  }, [])

  /**
   * For trial level uploads, that aren't guest uploads, prompt the user to select a site.
   */
  const shouldSelectSiteId = uploadInput.uploadLevel === UploadLevel.TRIAL && !uploadInput.guestUploader;

  const onSiteSelection = (siteId: number) => {
    setCachedSiteIdForOrganization(siteId);
    api.setSiteId(String(siteId));
    setSelectedSiteId(siteId);
  };

  useEffect(() => {
    if (
      uploadInput.uploadLevel === UploadLevel.ORDER &&
      uploadInput.order?.dicomFormattedScanDates
    ) {
      // If this is an order level upload, warn for studies outside the allowed date range.
      const orderScanDates = uploadInput.order.dicomFormattedScanDates;

      const studiesNotOnAnOrderScanDate: string[] = [];

      studyMetadata.forEach((study) => {
        const { StudyDate, TimezoneOffsetFromUTC, StudyTime } = study.header;

        const studyDate = dicomDateIncludingTimeZone(StudyDate, {
          TimezoneOffsetFromUTC: TimezoneOffsetFromUTC,
          dicomTime: StudyTime
        });

        if (!orderScanDates.includes(studyDate)) {
          studiesNotOnAnOrderScanDate.push(study.header.StudyDescription);
        }
      });

      setNewOrderScanStudies(studiesNotOnAnOrderScanDate);
    }

    const updatedStudyEntries: StudyEntry[] = studyMetadata.map((study) => {
      const { header, fileCount, StudyInstanceUID } = study;

      return {
        PatientID: header.PatientID,
        StudyDate: header.StudyDate ? formatDate(header.StudyDate) : undefined,
        Modality: header.Modality,
        StudyDescription: header.StudyDescription,
        fileCount: fileCount,
        StudyInstanceUID,
      };
    });

    let newDifferentMrnsValue = false;

    if (studyMetadata.length) {
      newDifferentMrnsValue = studyMetadata.some(
        (se) => se.header.PatientID !== studyMetadata[0].header.PatientID
      )
    }

    const newHasMissingStudyDatesValue = updatedStudyEntries.some(entry => entry.StudyDate === undefined);

    setHasMissingStudyDates(newHasMissingStudyDatesValue);
    setStudyEntries(updatedStudyEntries);
    setDifferentMrns(newDifferentMrnsValue);
  }, [studyMetadata]);

  const nextButtonDisabled = differentMrns
    || !studyEntries.length
    || (shouldSelectSiteId && selectedSiteId === undefined)
    || !studyDatesFilled
    || orderUploadSiteInactive

  const nextButtonClass = nextButtonDisabled
    ? 'next-prev-button next-prev-button_disabled'
    : 'next-prev-button';

  const onSetDicomDate = (StudyInstanceUID: string, dicomDate: string): void => {
    setStudyDateOverride((previousState: StudyDateOverride) => {
      const newState = { ...previousState };

      newState[StudyInstanceUID] = dicomDate;

      return newState;
    })
  };

  const onNextClick = () => {
    if (Object.keys(studyDateOverride).length) {
      api.setStudyDateOverride(studyDateOverride);
    }

    api.selectStudies();
  };

  const tableRows = studyEntries.map((studyEntry, index) => {
    return (
      <tr key={index}>
        <td>{studyEntry.PatientID}</td>
        <td className='select-studies-date'>
          {studyEntry.StudyDate
            ? studyEntry.StudyDate
            : <div className='select-studies-date-input'>
              <DateInput setNewDicomTag={(dicomDate) => onSetDicomDate(studyEntry.StudyInstanceUID, dicomDate)} />
            </div>}
        </td>
        <td>{studyEntry.Modality}</td>
        <td>{studyEntry.StudyDescription}</td>
        <td>{studyEntry.fileCount}</td>
        <td
          className="select-studies-delete"
          onClick={() => api.removeStudies([studyEntry.StudyInstanceUID])}
        >
          <FontAwesomeIcon icon={faCircleXmark} />
        </td>
      </tr>
    );
  });

  if (!studyEntries.length) {
    return null;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="select-studies">
        {unsupportedText ? (
          <p className="unsupported-files">
            {`Some selected files are not supported: ${unsupportedText}.`}
            <br />
            These files will be removed fom the upload.
          </p>
        ) : null}
        <div className="select-studies-table-container">
          <table className="select-studies-table">
            <tbody>
              <tr className="select-studies-table-header">
                <th>Patient ID</th>
                <th>Exam Date</th>
                <th>Modality</th>
                <th>Study Description</th>
                <th>Images</th>
                <th></th>
              </tr>
              {tableRows}
            </tbody>
          </table>
        </div>
        {differentMrns ? (
          <p className="select-studies-warning">
            Please upload data for a single Patient ID at a time
          </p>
        ) : null}
        {shouldSelectSiteId && uploadInput.trial ?
          <SiteSelector
            selectedSiteId={selectedSiteId}
            trial={uploadInput.trial}
            onChange={onSiteSelection}
            helpDeskEmailAddress={uploadInput.helpDeskEmailAddress}
          /> : null
        }
        <button
          className={nextButtonClass}
          disabled={nextButtonDisabled}
          onClick={onNextClick}
        >
          Next
        </button>
        {newOrderScanStudies.length ? (
          <p className="order-studies-warning">
            {getNewOrderScanMessage(newOrderScanStudies)}
          </p>
        ) : null}
        {orderUploadSiteInactive ? (
          <OrderUploadSiteInactiveWarning
            helpDeskEmailAddress={uploadInput.helpDeskEmailAddress}
          />
        ) : null}
      </div>
    </LocalizationProvider>
  );
};

export default SelectStudies;
